<template>
  <RegisterComponent />
</template>

<script>
import RegisterComponent from "../modules/register/Register";
export default {
  name: "register",
  components: {
    RegisterComponent,
  },
};
</script>
